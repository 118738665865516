import React from 'react'
import projectbannerimg1 from '../Images/Treasure-Island-12-693x390 4.png'
import './Project.css'
import projectscopeimg1 from '../Images/Treasure-Island-13-300x169.png'
import projectscopeimg2 from '../Images/Treasure-Island-Demolition-1-1-300x169.png'
import projectscopeimg3 from '../Images/Treasure-Island-Demolition-2-300x169.png'

import downloadpdfimg1 from '../Images/Group (25).svg'
import downloadpdfimg2 from '../Images/Group 1000008556 (1).svg'

import callsvg1 from '../Images/Vector (7).svg'
import sidbarimg11 from '../Images/Untitled design (79) 1.png'

function Project() {
  return (
    <div className='project-main-div11'>
      
      <div className='container-fluid project-banner-div1'>
        <div className='container-fluid p-0'>
          <div className='container'>
            <div className='project-banner-text-div1'>
              <h1>TREASURE ISLAND</h1>
            </div>
            <div className='project-banner-pic1'>
              <img src={projectbannerimg1} className='img-fluid' alt="" />
            </div>
          </div>
        </div>
        
      </div>

{/* 
      <div className='container-fluid position-relative'>
        <div className='container'>
          <div className='project-banner-pic1'>
            <img src={projectbannerimg1} className='img-fluid' alt="" />
          </div> 
        </div>
      </div> */}














      <div className='container-fluid project-scope-div1'>
        <div className='container'>
          <div className='row project-scope-row-div1'>
            <div className='col-lg-8'>
              <div className='project-scope-text-div1'>
                <h1>Project <span>Scope:</span></h1>
                <p>The historical landmark consisted of 420,000 square feet of a 3-story office building and a former hanger located on a 35-acre site. AMPCO was contracted to complete the abatement and demolition of the buildings. The environmental scope included asbestos and lead abatement, and removal of miscellaneous hazardous waste, Asbestos-containing materials included drywall, floor tile, and mastic, pipe insulation, wall mastic, and roof mastic. Lead-based materials included paint and ceramic tile.</p>
                <div className='project-scope-img-div1'>
                  <div className='project-scope-img-div2' >
                    <img src={projectscopeimg1} alt="" />
                    <img src={projectscopeimg2} alt="" />
                  </div>
                  <div className='project-scope-img-div3' >
                    <img src={projectscopeimg3} alt="" />
                  </div>
                </div>

                <h1>Means and <span>Methods:</span></h1>
                <p>The demolition scope included the implementation of SWPPP and the complete structural demolition of the 420,000 square feet Star Barracks building and an 82,000 square feet Hanger. All building slabs, footings, and underground utilities including transit pipe were removed. All concrete was crushed and stockpiled on-site for re-use in the grading scope totaling 70,500 tons.</p>
                <h1>Challenges <span>Faced:</span></h1>
                <ul>
                  <li>Performed demolition work next to multiple active buildings controlled by the U.S Coast Guard.</li>
                  <li>Constant dust control management due to high winds.</li>
                  <li>Dust control management due to crushing operations.</li>
                  <li>Multiple story buildings that required the strict adherence to the engineering plan to demolish safely</li>
                  <li>Working around wet soil conditions. Groundwater was 4’ below grade and created multiple hazards during footing removal.</li>
                </ul>
              </div>
            </div>


            <div className='col-lg-4'>
              <div className='project-sidebar-div1'>
                <div className='project-sidebar-btn-div1'>
                  <a href=""><div>General Construction</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Property Maintenance</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Project Management</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Virtual Design & Build</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Preconstruction</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Design Build</div></a>
                </div>



                <div className='project-download-btn-div1'>
                  <a href="">
                    <div>
                      <img src={downloadpdfimg1} alt="" />
                      Download Pdf
                      <img src={downloadpdfimg2} alt="" />
                    </div>
                  </a>
                </div>



                <div className='projrct-sidebar-build-div1'>
                  <img src={sidbarimg11} className='project-sidebarimg-div11' alt="" />
                  <div className='project-sidebar-build-btndiv1'>
                    <a href=""><div><img src={callsvg1} alt="" />+1317373890</div></a>
                    </div>  
                  <h2>Let’s build <br /> something great</h2>
                  <div className='project-sidebar-build-btndiv2'>
                    <a href=""><div>Get Started <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                      <path d="M1.34201 0.944666L1.324 2.93162L9.27159 3.02603L0.922225 11.2024L2.32858 12.6385L10.6779 4.46214L10.6059 12.41L12.5928 12.4336L12.6957 1.07954L1.34201 0.944666Z" fill="#F7901F" />
                    </svg></div></a>
                      </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Project
