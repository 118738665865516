import React from 'react'
import aboutimg1 from '../Images/l2_plant_ops 1.png'
import abouticon1 from '../Images/Group 1000008585.png'
import abouticon2 from '../Images/Layer 2.svg'
import abouticon3 from '../Images/Group 1000008584.png'
import './About.css'
import aboutsvg from '../Images/Vector (8).svg'


function About() {
  return (
    <div>
      <div className='container-fluid about-banner-div1'>
        <div className='container-fluid p-0'>
          <div className='container'>
            <div className='about-banner-text-div1'>
              <div className='about-banner-text-div2'>
                <h1>Build Plus</h1>
                <p>is <span>Union Contractor</span></p>
              </div>
              <div className='about-banner-text-div3'>
                <div><h2>15+</h2></div>
                <div><h4>Years of <br />
                  Service</h4></div>
              </div>
            </div>
            <div className='about-banner-pic1'>
              <img src={aboutimg1} className='img-fluid' alt="" />
            </div>
          </div>
        </div>

      </div>



















      {/* // ###################### section no 22 ### 3 ############# */}

      <div className='container-fluid about-demolition-div1'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='about-demolition-text-div1'>
                <p>We specializes in </p>
                <h2>Demolition
                  Environmental
                  Earthwork</h2>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='about-demolition-text-div2'>
                <p> At Build Plus Survey LTD, we specialise in providing high-quality construction, renovation, and property improvement services across the UK. Established with a passion for creating beautiful and functional spaces, our team is dedicated to excellence in every project we undertake, whether large or small. </p>
                <p> Our services range from bespoke home extensions and new builds to full house renovations and loft conversions. We take pride in offering tailored solutions, ensuring that each project is completed on time, within budget, and to the highest standards of craftsmanship.      </p>
                <p>Our skilled professionals bring years of experience and a customer-first approach to every project. We work closely with our clients to bring their visions to life, paying attention to detail and ensuring complete satisfaction.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>



















      {/* // ###################### section no 33 ### 3 ############# */}


      <div className='container-fluid about-last-sec-div1'>
        <img src={aboutsvg} className='about-vector-svg' />
        <img src={aboutsvg} className='about-vector-svg2' />

        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='about-last-sec-div2'>
                <div className='about-last-sec-img-div1'>
                  <img src={abouticon1} alt="" />
                </div>
                <div >
                  <h3>LICENSING</h3>
                  <p>We are fully licensed and compliant with UK building regulations, ensuring all projects meet the necessary legal standards for construction and safety.
</p>
                </div>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='about-last-sec-div2'>
                <div className='about-last-sec-img-div1'>
                  <img src={abouticon2} alt="" />
                </div>
                <div >
                  <h3>BONDING</h3>
                  <p>A + Bond rating and capable of bonding $40,000,000 single project with an $80,000,000 aggregate</p>
                </div>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='about-last-sec-div2'>
                <div className='about-last-sec-img-div1'>
                  <img src={abouticon3} alt="" />
                </div>
                <div >
                  <h3>INSURANCE</h3>
                  <p>We carry comprehensive insurance to protect both our clients and our team, ensuring peace of mind throughout the project
</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
