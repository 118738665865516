import React from 'react'
import safetybannerimg1 from '../Images/image1-9 1.png'
import './Project.css'
import './Services.css'
import './Safety.css'

function Safety() {
  return (
    <div>

      <div className='container-fluid safety-banner-div1'>
        <div className='container-fluid p-0'>
          <div className='container'>
            <div className='safety-banner-text-div1'>
              <div className='safety-banner-text-div2'>
                <h1>Safety Leads</h1>
                <p>to Operational <br /><span> Excellence</span></p>
              </div>
              <div className='safety-banner-text-div3'>
                <p>Our goal is to keep safety top of mind and develop safety into a Keystone Habit for all employees and subcontractors. Keystone habits are the routines and practices by which someone operates. They mark the base level of what you do without any need for willpower or persuasion. The default. Build Plus has a rigorous, boots-on-the-ground safety training program that takes a hands safety approach for all projects. </p>
              </div>
            </div>
            <div className='safety-banner-pic1'>
              <img src={safetybannerimg1} className='img-fluid' alt="" />
            </div>
            <div className='safety-para1-div1'>
              <p>Our safety program is based on past and real-time issues that pertain to the work that we self-perform day to day. Every project is unique and requires specific engineering controls associated with specific tasks. Projects are getting larger, taller, deeper, and more expansive in many ways. Build Plus is prepared for any project with proper safety planning and engineering.</p>
            </div>
          </div>
        </div>

      </div>

      
    </div>
  )
}

export default Safety
