import Home from './Components/Home'
import NavBar from './Components/NavBar.js'
import Footer from './Components/Footer'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Services from './Components/Services';
import About from './Components/About'
import Contact from './Components/Contact';
import Project from './Components/Project';
import Safety from './Components/Safety.js';
import ScrollToTop from './Components/ScrollToTop.js'


function App() {
  return (
    <div>

      <BrowserRouter>
        <ScrollToTop></ScrollToTop>

        <NavBar></NavBar>
        <Routes>

          <Route path="/" element={<Home></Home>} />
          <Route path="/service" element={<Services></Services>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/project" element={<Project></Project>} />
          <Route path="/safety" element={<Safety></Safety>} />
          <Route path="/contact" element={<Contact></Contact>} />


        </Routes>
        <Footer></Footer>
      </BrowserRouter>

    </div>

  );
}

export default App;
