import React from 'react'
import contactimg from '../Images/Le-Conte-Apartments-1-1024x576.png'
import './Contact.css'
import contacticons1 from '../Images/Group 1000008671.svg'
import contacticons2 from '../Images/Group 1000008670.svg'


function Contact() {
  return (
    <div>
      <div className='container-fluid contact-banner-div1'>
        <div className='container-fluid p-0'>
          <div className='container'>
            <div className='contact-banner-text-div1'>
              <div className='contact-banner-text-div2'>
                <h1>LET’S KICK OFF YOUR</h1>
                <p>NEXT<span> PROJECT TOGETHER</span></p>
              </div>
              <div className='contact-banner-text-div3 d-flex justify-content-end'>
                <p>Build Plus has been working in the construction <br className='d-md-block d-none' /> industry since 2014. We’d be excited to start our next <br /> one with you.</p>
              </div>
            </div>
            <div className='contact-banner-form-div1'>
              <div>
                <form>
                  <div class="form-row">
                    <div class="col-lg-6">
                      <input type="text" class="form-control input-field12" placeholder="First name" />
                    </div>
                    <div class="col-lg-6">
                      <input type="text" class="form-control input-field12" placeholder="Last name" />
                    </div>
                  </div>
                  <div class="">
                    <textarea class="form-control text-area12" rows={8} placeholder='Message'></textarea>
                  </div>
                  <div>
                    <button className='form-btn12'>Send message <span>{'->'}</span></button>
                  </div>  
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>























      <div className='container-fluid contact-location-div1'>
        <div className='container'>
          <div className='row'>
          

            <div className='col-lg-6 d-flex align-items-center justify-content-lg-center justify-content-start'>
              <div className='contact-location-text-div1'>
                <h1>Office Locations:</h1>
                <div className='contact-location-text-div2'>
                  <div>
                    <img src={contacticons1} alt="" />
                  </div>
                  <div>
                    <p>250 Chapman Street, Manchester, M18 8WQ</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-6 d-flex align-items-center justify-content-lg-center justify-content-start'>
              <div className='contact-location-text-div1'>
                <h1>Interested?</h1>
                <div className='contact-location-text-div2'>
                  <div>
                    <img src={contacticons2} alt="" />
                  </div>
                  <div>
                    <p>07540 066073</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
