import React from 'react'
import servicessvg1 from '../Images/demolition-icon (1).svg'
import servicesbannerimg1 from '../Images/Amazon-3 2.png'
import './Project.css'
import './Services.css'
import servicesscopeimg1 from '../Images/Rectangle 41165.png'

import downloadpdfimg1 from '../Images/Group (25).svg'
import downloadpdfimg2 from '../Images/Group 1000008556 (1).svg'

import callsvg1 from '../Images/Vector (7).svg'
import sidbarimg11 from '../Images/Untitled design (79) 1.png'

function Services() {
  return (
    <div>


      <div className='container-fluid services-banner-div1'>
        <div className='container-fluid p-0'>
          <div className='container'>
            <div className='services-banner-text-div1'>
              <div className='services-banner-text-div2'>
                <img src={servicessvg1} />
                <h1>Earth work</h1>
              </div>
              <div className='services-banner-text-div3'>
                <p>AMPCO’s Earthwork Division performs mass grading, rough grading, finish grading, excavation and hazardous soil remediation. Our experienced team uses the latest laser technology and heavy equipment to grade the tightest commercial sites, as well as the largest, most complex earthwork projects.</p>
            </div>
            </div>
            <div className='services-banner-pic1'>
              <img src={servicesbannerimg1} className='img-fluid' alt="" />
            </div>
          </div>
        </div>

      </div>























      <div className='container-fluid services-scope-div1'>
        <div className='container'>
          <div className='row services-scope-row-div1'>
            <div className='col-lg-8'>
              <div className='services-scope-text-div1'>
                <h1>Our Comprehensive Earthwork Services</h1>
                <p>AMPCO’s Earthwork Division performs mass grading, rough grading, finish grading, excavation and hazardous soil remediation. Our experienced team uses the latest laser technology and heavy equipment to grade the tightest commercial sites, as well as the largest, most complex earthwork projects.</p>
                <p>
                  Our well maintained fleet of heavy equipment and experienced project managers ensure that projects are completed on time and within budget. Whether it is mass grading, excavation or hazardous soil remediation, AMPCO’s Earthwork Division is the go-to contractor for projects between one and 1,000 acres.</p>

                  <div className='services-scope-img-div1' >
                  <img src={servicesscopeimg1} className='img-fluid' alt="" />
                  </div>
                <h1>Expert Earthwork Services for Strong Foundations</h1>
                <p>The integrity of your real estate project is only as strong as the earth it rests on. Effective earthwork takes a combination technical knowledge and field experience. Our team is highly trained in both aspects of the project. Our experience allows our team to consult our clients on the correct and most practical methods to complete the project cost effectively while ensuring each pad site is structurally sound for vertical construction. AMPCO does not just move dirt, we value engineer every project. Our earthwork division also coordinates with the demolition division to find ways to improve production between each phase. This allows our earthwork proposals to be competitive while improving schedules.</p>
                
              </div>
            </div>


            <div className='col-lg-4'>
              <div className='project-sidebar-div1'>
                <div className='project-sidebar-btn-div1'>
                  <a href=""><div>General Construction</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Property Maintenance</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Project Management</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Virtual Design & Build</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Preconstruction</div></a>
                </div>
                <div className='project-sidebar-btn-div2'>
                  <a href=""><div>Design Build</div></a>
                </div>



                <div className='project-download-btn-div1'>
                  <a href="">
                    <div>
                      <img src={downloadpdfimg1} alt="" />
                      Download Pdf
                      <img src={downloadpdfimg2} alt="" />
                    </div>
                  </a>
                </div>



                <div className='projrct-sidebar-build-div1'>
                  <img src={sidbarimg11} className='project-sidebarimg-div11' alt="" />
                  <div className='project-sidebar-build-btndiv1'>
                    <a href=""><div><img src={callsvg1} alt="" />+1317373890</div></a>
                  </div>
                  <h2>Let’s build <br /> something great</h2>
                  <div className='project-sidebar-build-btndiv2'>
                    <a href=""><div>Get Started <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                      <path d="M1.34201 0.944666L1.324 2.93162L9.27159 3.02603L0.922225 11.2024L2.32858 12.6385L10.6779 4.46214L10.6059 12.41L12.5928 12.4336L12.6957 1.07954L1.34201 0.944666Z" fill="#F7901F" />
                    </svg></div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Services
