import React from 'react'
import './Footer.css'
import footerlogo1 from '../Images/img-3.svg'
import logo1 from '../Images/Group 1000008724 (1).svg'
import logo2 from '../Images/img-4.svg'
import logo3 from '../Images/img-1.svg'
import socialicon1 from '../Images/Color.svg'
import socialicon2 from '../Images/Group (23).svg'
import socialicon3 from '../Images/Group 1000008726.svg'
import socialicon4 from '../Images/Group (24).svg'
import { Link } from 'react-router-dom'


function Footer() {
    return (
        <div>
            <div className='container-fluid footer-div1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div>
                                <Link to='/'><h1 className='footer-logo-div1'>Build<span>+</span></h1></Link>

                            </div>
                        </div>

                        <div className='col-lg-4 mt-lg-0 mt-3'>
                            <div className='footer-text-div2'>
                                <h4>Contact Info</h4>
                                <a href='tel:07540066073'>
                                    <p>Phone <br />
                                        07540 066073</p>
                                </a>
                                <a href='mailto:buildplussurvey2010@gmail.com'>
                                    <p>Email <br />
                                        buildplussurvey2010@gmail.com</p>
                                </a>
                                    <p>Address <br />
                                        250 Chapman Street, Manchester, M18 8WQ</p>
                            </div>
                        </div>



                        <div className='col-lg-4'>
                            <div className='footer-text-div2'>
                                <h4>Services</h4>
                                <Link to='/service'>
                                    <p>Extensions<br />
                                        New Build Houses <br />
                                        Roofing & Joinery Work <br />
                                        Full House Renovation <br />
                                        K-Rendering & Plastering <br />
                                        Bathroom Renovations</p>
                                </Link>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
