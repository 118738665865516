import React from 'react'
import './Home.css'
import bannersvg1 from '../Images/Group 1000008557 (1).svg'
import bannersvg2 from '../Images/Group 1000008566.svg'

import bannerimg1 from '../Images/image 1866.svg'
import bannerimg2 from '../Images/WhatsApp Image 2024-09-27 at 11.59.18 PM.jpeg'
import vehicleimg1 from '../Images/The-Importance-Of-Site-Work-What-Happens-Before-Building-Begins 1.png'
import vehicleimg2 from '../Images/02-Updated-Equipment 1.png'
import vehicleimg3 from '../Images/pexels-gaion-5668974 1.png'
import vehicleimg4 from '../Images/pexels-gaion-5669019 1.png'


import homeserviceicon1 from '../Images/demolition-icon.svg'
import homeserviceicon2 from '../Images/crane (5).png'
import homeserviceicon3 from '../Images/Earthwork-Icon (1).svg'
import homeserviceicon4 from '../Images/Heavy-Highway-Icon.svg'
import homeserviceicon5 from '../Images/crushing-icon.svg'
import homeserviceicon6 from '../Images/excavator (1).svg'

import homeprojectimg1 from '../Images/Treasure-Island-12-693x390 2.png'
import homeprojectimg2 from '../Images/YBI-1-693x390 1.png'
import homeprojectimg3 from '../Images/1001-Van-Ness-3-693x390 1.png'
import homeprojectimg4 from '../Images/Le-Conte-Apartments-5-1-693x390 1.png'
import homeprojectimg5 from '../Images/Budokon-1-1-693x390 1.png'

import clientlogo1 from '../Images/lennar.png'
import clientlogo2 from '../Images/Turner.png'
import clientlogo3 from '../Images/hathaway.png'
import clientlogo4 from '../Images/kemp.png'
import clientlogo5 from '../Images/PCL.png'
import clientlogo6 from '../Images/desilva.png'
import clientlogo7 from '../Images/hensel.png'
import clientlogo8 from '../Images/pacific-1.png'

import inqueryimg1 from '../Images/image 1872.svg'

import { Link } from 'react-router-dom'



function Home() {
    return (
        <div>

            <div className=''>
                <div className='container-fluid home-banner-div1 '>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='home-banner-text-div1'>
                                    <p>We Specialise in</p>

                                    <h1>Construction{' '}
                                        <div className='clients-text1'>
                                            <img src={bannersvg1} alt="" />
                                            <div>
                                                <h4>200+</h4>
                                                <p>Clients</p>
                                            </div>
                                        </div>Renovation <br className='d-md-none d-block' />
                                        <div className='clients-text1'>
                                            <img src={bannersvg2} alt="" />
                                            <div>
                                                <h4>1500+</h4>
                                                <p>Projects</p>
                                            </div>
                                        </div> Property Services</h1>

                                    <div>
                                        <Link to="/contact">
                                            <div>Get In Touch <span>{'->'}</span></div>
                                        </Link>
                                    </div>

                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div>
                                    <img src={bannerimg2} className='img-fluid ' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid home-banner-div22'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 '>
                                <div className='home-banner2-text-div22'>
                                    <p><span>Build Plus</span> is a multidisciplinary practice with a reputation for superior Extensions, New Build Houses, Roofing & Joinery Work, Full House Renovation, K-Rendering & Plastering, Bathroom Renovations and Dormer & Loft Conversions
                                        services throughout Manchester.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


















            {/* // ###################### section no ### 2 ############# */}




            <div className='container-fluid home-sec2-div1'>
                <div className='container'>
                    <div className='home-sec2-text-div1'>
                        <p>At Build Plus Survey LTD, we've transformed from a skilled local builder into a trusted name in complete property renovations and construction services across the UK. With over <span>15 years of experience </span> in bringing visions to life, we've completed hundreds of projects ranging from bespoke new builds to extensive renovations and unique loft conversions.</p>
                        <p>We're dedicated to excellence in every job, whether it's crafting the perfect home extension, renovating a historic property, or creating modern living spaces with innovative designs. Our team’s commitment to quality craftsmanship and timely delivery ensures that every client’s dream is built to last.</p>
                    </div>

                    <div className='home-sec2-img-div'>
                        <div className='home-sec2-img1-div1'>
                            <img src={vehicleimg1} alt="" />
                        </div>
                        <div className='home-sec2-img2-maindiv1'>
                            <div className='home-sec2-img3-div1'>
                                <div>
                                    <h1>15</h1>
                                    <p>YEARS EXPERIENCE <br />
                                        AS CONTRACTOR</p>
                                </div>
                            </div>
                            <div className='home-sec2-img4-div1'>
                                <img src={vehicleimg2} alt="" />
                            </div>
                            <div className='home-sec2-img5-div1'>
                                <img src={vehicleimg3} alt="" />
                            </div>
                            <div className='home-sec2-img6-div1' >
                                <img src={vehicleimg4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


















            {/* // ###################### section no 33 ### 3 ############# */}


            <div className='container-fluid home-service-div1' id='services'>
                <div className='container'>
                    <div className='home-service-text-div1'>
                        <div className='home-service-text-div2'>
                            <p>Our</p>
                            <h2>Services</h2>
                        </div>
                        <div className='home-service-text-div3 d-md-block d-none '>
                            <a href="">
                                <div>All Services<span> {'->'}</span></div>
                            </a>
                        </div>
                    </div>

                    <div className='row home-service-row-div11'>

                        {/* ####### card no 1 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className=' home-construction-icons'>
                                    <img src={homeserviceicon1} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>Extensions</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ####### card no 2 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className=' home-construction-icons'>
                                    <img src={homeserviceicon2} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>New Build  <br /> Houses</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ####### card no 1 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className=' home-construction-icons'>
                                    <img src={homeserviceicon3} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>Roofing & <br /> Joinery Work</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ####### card no 1 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className=' home-construction-icons'>
                                    <img src={homeserviceicon4} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>Full House <br /> Renovation</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ####### card no 1 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className=' home-construction-icons'>
                                    <img src={homeserviceicon5} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>K-Rendering <br /> & Plastering</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ####### card no 1 ######## */}
                        <div className='col-lg-4'>
                            <div className='home-service-card-div1'>
                                <div className='home-service-card-img22'>
                                    <img src={homeserviceicon6} alt="" />
                                </div>
                                <div className='home-service-card-textdiv1'>
                                    <h4>Bathroom <br /> Renovations</h4>
                                    <div>
                                        <Link to=''><div>Learn More</div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>






















            <div className='container-fluid home-inquiry-div1'>
                <div className='home-inquiry-div1122'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 d-md-none d-block'>
                                <div className='inquery-img-div11'>
                                    <img src={inqueryimg1} className='img-fluid' alt="" />
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='home-inquiry-text-div1'>
                                    <h2>For Further Inquiries</h2>
                                    <div className='home-inquiry-btn1  '>
                                        <a href="tel:07540066073">
                                            <div>Call Us <span>{'->'}</span></div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-7 d-md-block d-none'>
                                <div className='inquery-img-div11'>
                                    <img src={inqueryimg1} className='img-fluid' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home
